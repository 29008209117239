import React from 'react';
import { useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { useStoreActions, useStoreState } from '~store/hooks';
import { StyledContainer } from '~components/styled/container';
import { Loader } from '~components/loader';
import { notificationsPageLinks } from '../links';
import { LeftColumn } from './left-column';
import { MiddleColumn } from './middle-column';
import { StyledRow } from './styles';
import { RightColumn } from './right-column';
import { isNotificationsPage } from '../helpers';
import { ControlColumn } from './control-column';

export const CreateNotification: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [tenantSegmentMap, setTenantSegmentMap] = React.useState<Record<string, string>>({});
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const { persistNotification, resetModel } = useStoreActions((state) => state.createNotification);
  const { notification } = useStoreState((state) => state.createNotification);
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ notificationId: string }>();
  const router = useRouteMatch();
  const isOnNotificationPage = isNotificationsPage(router);

  React.useEffect(() => {
    if (id) {
      persistNotification({
        notificationId: id,
        onComplete() {
          setLoading(false);
        }
      });
    } else if (location?.state?.notificationId) {
      persistNotification({
        notificationId: location.state.notificationId,
        clone: true,
        onComplete() {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    // ensure we only call reset if we're not editing/duplicating a notification (i.e it's brand new)
    if (!location?.state?.notificationId && !id) {
      resetModel({
        type: isOnNotificationPage ? NOTIFICATION_TYPE.STANDARD : NOTIFICATION_TYPE.INAPP,
        ...(isOnNotificationPage ? {} : { inApp: { type: INAPP_TYPE.STANDARD } })
      });
    }
  }, [location, id]);

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Notifications',
      activeChild: 'pushNotification',
      links: notificationsPageLinks,
      warningMessage:
        !isOnNotificationPage && notification?.inApp?.type === INAPP_TYPE.VIDEO
          ? 'Inapp Video Notifications only supported in SDK 4.7.0 and above'
          : null
    });
  }, [notification?.inApp?.type]);

  React.useEffect(() => {
    if (!id) {
      resetModel({
        type: isOnNotificationPage ? NOTIFICATION_TYPE.STANDARD : NOTIFICATION_TYPE.INAPP,
        ...(isOnNotificationPage ? {} : { inApp: { type: INAPP_TYPE.STANDARD } })
      });
    }
  }, [id]);

  return (
    <>
      <StyledContainer $sideNavVisible={visibility === 'show'}>
        <StyledRow>
          <Loader loading={loading} backdrop />
          <LeftColumn />
          <MiddleColumn />
          <RightColumn tenantSegmentMap={tenantSegmentMap} setTenantSegmentMap={setTenantSegmentMap} />
          <ControlColumn tenantSegmentMap={tenantSegmentMap} />
        </StyledRow>
      </StyledContainer>
    </>
  );
};
