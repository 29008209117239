import { State, StateMapper } from 'easy-peasy';
import {
  NOTIFICATION_TYPE,
  PersonalisationConfigItemType
} from '@pushologies/database-service/db/entities/notification';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { CreateNotificationModel } from './types';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';

// tslint:disable-next-line: cyclomatic-complexity
export function processStateNotificationForApi(state: StateMapper<State<CreateNotificationModel>>) {
  const {
    videoContent,
    bannerContent,
    richImageContent,
    carouselContent,
    buttons,
    scheduling,
    subTitle,
    inApp,
    personalisation,
    segment,
    ...otherNotificationFields
  } = state.notification;

  const contents: Record<string, any>[] = [];
  let inAppObj = {};

  if (otherNotificationFields.type === NOTIFICATION_TYPE.VIDEO || inApp?.type === INAPP_TYPE.VIDEO) {
    contents.push({
      id: videoContent?.id,
      options: videoContent?.options,
      defaultContent: videoContent?.defaultContent,
      contentItem: { id: videoContent?.contentItem?.id }
    });

    contents[0].options.buttons = Object.values(buttons).map(({ displayName, options }) => ({
      displayName,
      url: options?.url?.trim(),
      linkHandling: options?.linkHandling,
      text: options?.text,
      textColor: options?.textColor,
      textAlignmentVertical: options?.textAlignmentVertical,
      textAlignmentHorizontal: options?.textAlignmentHorizontal,
      textWrapping: options?.textWrapping,
      font: options?.font,
      ...(options?.fontSize && { fontSize: `${options.fontSize}` }),
      backgroundColor: options?.backgroundColor,
      borderColor: options?.borderColor,
      contentItemId: options?.contentItemId,
      fullScreen: options?.fullScreen,
      position: options.position,
      buttonAppear: options?.buttonAppear,
      ...(options.buttonDisappear &&
        options.buttonDisappear !== Infinity && { buttonDisappear: Math.floor(options.buttonDisappear) }),
      ...(options.tagName && { tagName: options.tagName })
    }));
  }

  if (otherNotificationFields.type === NOTIFICATION_TYPE.CAROUSEL || inApp?.type === INAPP_TYPE.CAROUSEL) {
    carouselContent.forEach((content, sortOrder) => {
      contents.push({
        id: content?.id,
        sortOrder,
        options: {
          buttons: [{ url: content.url }]
        },
        contentItem: { id: content?.contentItem?.id },
        presentation: CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL
      });
    });
  }

  if (otherNotificationFields.type === NOTIFICATION_TYPE.INAPP) {
    // Remove forbidden fields that have default value as a result of api validation
    if (inApp.type !== INAPP_TYPE.STANDARD) {
      if (inApp.closeLabel) delete inApp.closeLabel;
      delete otherNotificationFields.message;
    }

    // Remove inApp keys that have value of ''

    inAppObj = Object.keys(inApp)
      // @ts-ignore:next-line
      .filter((k) => inApp[k] !== '')
      // @ts-ignore:next-line
      .reduce((a, k) => ({ ...a, [k]: inApp[k] }), {});
  }

  if (bannerContent?.contentItem?.id) {
    contents.push({
      id: bannerContent?.id,
      defaultContent: bannerContent?.defaultContent,
      contentItem: { id: bannerContent?.contentItem?.id },
      presentation: CONTENT_ITEM_PRESENTATION_TYPE.BANNER
    });
  }

  if (richImageContent?.contentItem?.id) {
    contents.push({
      id: richImageContent?.id,
      defaultContent: richImageContent?.defaultContent,
      contentItem: { id: richImageContent.contentItem.id },
      presentation: CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE
    });
  }

  const schedule: Record<string, any> = { ...scheduling };
  if (scheduling && scheduling.mode === 'TIMEZONE') {
    const date = new Date(schedule.pushAt);
    schedule.localTime = {
      hour: date.getHours(),
      minute: date.getMinutes()
    };
  }

  // only send name, modifiers in variable as what api only accept. In future may expand
  let personalisationObjects: { uuid: string; variable: Partial<PersonalisationConfigItemType> }[] = [];
  const hasValidPersonalisation = !!personalisation && Array.isArray(personalisation);
  if (hasValidPersonalisation) {
    personalisationObjects = personalisation.map((item) => ({
      ...item,
      variable: {
        name: item.variable.name,
        modifiers: item.variable.modifiers
      }
    }));
  }

  return {
    ...otherNotificationFields,
    ...(!!subTitle && { subTitle }),
    ...(contents.length && { contents }),
    ...(!!scheduling && { schedule }),
    ...(!!inApp && { inApp: inAppObj }),
    ...(hasValidPersonalisation && { personalisation: personalisationObjects }),
    segment: segment || null,
    ttl: 604800
  };
}
