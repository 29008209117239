import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Select, SelectOption } from '~components/select';
import { useStoreActions, useStoreState } from '~store/hooks';
import { INAPP_TRIGGERS, isInAppPage } from '../../helpers';
import { InAppSection, LabelDiv } from './styles';
import { TextField } from '~components/inputs';

const InAppTriggerOptions: SelectOption<INAPP_TRIGGERS>[] = [
  {
    name: 'On Open',
    value: INAPP_TRIGGERS.APP_OPEN
  },
  {
    name: 'On Custom Event (SDK)',
    value: INAPP_TRIGGERS.CUSTOM_TRIGGER
  }
];

export const InAppInputs: React.FC = () => {
  const router = useRouteMatch();
  const { notification } = useStoreState((state) => state.createNotification);
  const [trigger, setTrigger] = React.useState<INAPP_TRIGGERS>(notification?.inApp?.trigger);
  // const [customEvent, setCustomEvent] = React.useState(notification?.inApp?.customEvent);
  const { setInApp } = useStoreActions((state) => state.createNotification);

  const handleTriggerChange = ([option]: SelectOption<INAPP_TRIGGERS>[]) => {
    setTrigger(option.value);
    setInApp({
      ...notification.inApp,
      customEvent: option.value !== INAPP_TRIGGERS.APP_OPEN ? notification?.inApp?.customEvent : undefined,
      trigger: option.value
    });
  };
  const handleCustomEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInApp({
      ...notification.inApp,
      customEvent: event.target.value
    });
  };

  React.useEffect(() => {
    setTrigger(notification.inApp?.trigger);
  }, [notification]);

  return useMemo(
    () =>
      isInAppPage(router) && (
        <InAppSection data-testid="inAppTriggerSection">
          <LabelDiv>
            <label htmlFor="inAppTrigger">
              Trigger
              <span>Event to trigger the in-app notification</span>
            </label>
          </LabelDiv>
          <Select
            id="inAppTrigger"
            testId="inAppTriggerSelect"
            value={InAppTriggerOptions.filter((option) => option.value === trigger)}
            options={InAppTriggerOptions}
            onChange={handleTriggerChange}
            drop="down"
            placeholder="Select Trigger"
            inValid={false}
          />
          <LabelDiv>
            <label htmlFor="inAppCustomEvent">
              Custom Event (SDK)
              <span>Event to trigger the in-app notification when using a custom trigger</span>
            </label>
          </LabelDiv>
          <TextField
            name="InAppCustomEventTextField"
            value={notification?.inApp?.customEvent}
            onChange={handleCustomEventChange}
            disabled={notification?.inApp?.trigger !== INAPP_TRIGGERS.CUSTOM_TRIGGER}
          />
        </InAppSection>
      ),
    [notification?.inApp, notification?.inApp?.trigger, trigger]
  );
};
