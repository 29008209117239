import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class TagsRule extends Rule {
  constructor(private rule: SegmentRule<string[]>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    if (!this.rule.value?.length) return;
    switch (this.rule.operator) {
      case Op.in:
        const [condition, params] = this.getQueryString();
        query.innerJoin('subscriber.tags', 'tag', condition, params);
        break;
      case Op.notIn:
        const tagsParam = this.createParameterHash();
        query.andWhere(
          (qb) => {
            const subQuery = qb
              .subQuery()
              .distinct()
              .select('innersubscriber.id')
              .from(Subscriber, 'innersubscriber')
              .innerJoin('innersubscriber.tags', 'tag')
              .where(`tag.name IN(:...${tagsParam}) AND tag.tenantId = subscriber.tenantId`)
              .getQuery();
            return 'subscriber.id NOT IN ' + subQuery;
          },
          { [tagsParam]: this.rule.value }
        );

        break;
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }

  private getQueryString(): [string, object] {
    const tagsParam = this.createParameterHash();
    switch (this.rule.operator) {
      case Op.in:
        return [
          `tag.name IN(:...${tagsParam}) AND tag.tenantId = subscriber.tenantId`,
          { [tagsParam]: this.rule.value }
        ];
      // case Op.notIn:
      //   return ['tag.name NOT IN(:...tags)', { tags: this.rule.value }];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
