import styled from 'styled-components';
import { Section, SECTION_HEIGHT } from '../styles';
import { breakpoints } from '~styles/mixins';

export const MetricsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${SECTION_HEIGHT};
  margin-bottom: 1rem;
`;

export const TopMetricsDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15rem;
  margin-bottom: 0.5em;
`;

export const LegacyTopMetricsDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(calc(100% / 3.75) - 0.5rem);
`;

export const MetricsTabsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  height: calc(calc(100% / 1.35) - 0.5rem);
`;

export const MetricsRowDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 2;
`;

const Metric = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: EavesBold;
  text-transform: uppercase;
  letter-spacing: 0.025rem;

  span {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-left: 0.5rem;

    i {
      font-size: 0.6rem;
      padding-right: 0.25rem;
    }

    &:first-child {
      width: 65%;
      color: ${({ theme }) => theme.palette.blue};
    }
    &:last-child {
      width: 35%;
      color: white;
      font-size: 1.75rem;
    }
  }

  @media ${breakpoints.lg} {
    span {
      line-height: 1rem;

      i {
        font-family: EavesRegular;
      }

      &:first-child {
        font-size: 1rem;
      }
      &:last-child {
        font-size: 1.25rem;
      }
    }
  }
`;

export const TabMetric = styled(Metric)`
  height: calc(calc(100% / 4) - 0.3rem);
  border-left: 0.5rem solid ${({ theme }) => theme.palette.blue};

  span {
    &:first-child {
      background: ${({ theme }) => theme.palette.lighterGrey};
    }
    &:last-child {
      background: ${({ theme }) => theme.palette.blue};
    }
  }
`;

export const TopMetric = styled(Metric)`
  border-left: 0.5rem solid #c3dde8;
  flex: 1;
  margin-bottom: 0.5em;

  span {
    &:first-child {
      width: 50%;
      background: ${({ theme }) => theme.palette.lighterGrey};
    }
    &:last-child {
      width: 50%;
      background: linear-gradient(90deg, #93bcd4 0%, #679bcc 100%);
    }
  }
`;
