import { action, thunk } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { SegmentModel } from './types';

export * from './types';

export const LIMIT = 50;

export const segmentModel: SegmentModel = {
  loading: 'idle',
  error: null,
  totalItems: LIMIT,

  setModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  fetchSegments: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setModel({ loading: 'fetchingSegments' });
    const { onSuccess, tenantId, ...params } = payload || {};

    try {
      const { segments, totalItems } = await (tenantId
        ? injections.segmentsApi.fetchByProxy(params, tenantId)
        : injections.segmentsApi.fetch(params));

      actions.setModel({ loading: 'idle', error: null });

      onSuccess(segments, totalItems);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ loading: 'idle', error: error.message });
    }
  }),

  fetchSegment: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setModel({ loading: 'fetchingSegment' });
    const { id, tenantId, onSuccess } = payload;

    try {
      const segment = await (tenantId
        ? injections.segmentsApi.fetchSegmentByProxy(id, tenantId)
        : injections.segmentsApi.fetchSegment(id));

      actions.setModel({ loading: 'idle', error: null });

      if (onSuccess) onSuccess(segment);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      actions.setModel({ loading: 'idle', error: error.message });
    }
  }),

  fetchCount: thunk(async (dispatch, payload, { injections, getStoreActions }) => {
    dispatch.setModel({ loading: 'fetchingCount' });
    const { rules, segmentBucketId, onSuccess } = payload;
    let totalCount = 0;
    let subscribedCount = 0;

    try {
      if (rules) {
        [{ count: subscribedCount }, { count: totalCount }] = await Promise.all([
          await injections.segmentsApi.fetchCount({ rules }),
          await injections.segmentsApi.fetchCount({ rules, excludeUnsubscribers: false })
        ]);
      } else {
        const { count } = await injections.segmentsApi.fetchCount({ segmentBucketId });
        totalCount = count;
      }
      dispatch.setModel({ loading: 'idle' });
      onSuccess(subscribedCount, totalCount - subscribedCount);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: `Fetch Segment Count Error: ${error.message}`
      });
      dispatch.setModel({ loading: 'idle' });
    }
  }),

  deleteSegment: thunk(async (_actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, id } = payload;

    try {
      await injections.segmentsApi.delete(id);

      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: 'Segment successfully deleted'
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      if (onError) onError(error.message);
    }
  })
};
