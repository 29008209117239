import React from 'react';
import { InputSection } from '~components/inputs/styles';
import { StyledDateTimePicker, StyledDateTimeRangePicker, StyledCalendarIcon, StyledDateRangePicker } from './styles';

interface DateTimeProps {
  id?: string;
  label?: string;
  testId?: string;
  value: Date;
  onChange(date: Date): void;
  invalid?: boolean;
  disabled?: boolean;
}

export const DateTimePicker: React.FC<DateTimeProps> = (props) => {
  return (
    <InputSection invalid={props.invalid} data-testid={props.testId}>
      {props.label && <label>{props.label}</label>}
      <StyledDateTimePicker
        id={props.id}
        data-testid={props.id}
        value={props.value ? new Date(props.value) : null}
        onChange={props.onChange}
        clearIcon={null}
        disableClock={true}
        calendarIcon={<StyledCalendarIcon disabled={props.disabled} />}
        disabled={props.disabled}
      />
    </InputSection>
  );
};

interface DateRangeProps {
  value: [Date, Date];
  placeholder?: string;
  onChange(value: [Date, Date]): void;
  invalid?: boolean;
  disabled?: boolean;
}

export const DateRangePicker: React.FC<DateRangeProps> = ({ value = [], onChange, invalid, placeholder, disabled }) => {
  return (
    <StyledDateRangePicker
      value={[value && value[0] ? new Date(value[0]) : null, value && value[1] ? new Date(value[1]) : null]}
      onChange={onChange}
      clearIcon={null}
      disableClock={true}
      dayPlaceholder={placeholder}
      monthPlaceholder={!!placeholder && ''}
      yearPlaceholder={!!placeholder && ''}
      calendarIcon={<StyledCalendarIcon $disabled={disabled} />}
      invalid={invalid}
      $hasPlaceholder={!!placeholder}
      $hasDate={!!value?.length}
    />
  );
};

// TODO: make datetime range input support placeholder text
type DateTimeRangeProps = Omit<DateRangeProps, 'placeholder'>;

export const DateTimeRangePicker: React.FC<DateTimeRangeProps> = ({ value = [], onChange, invalid }) => {
  return (
    <StyledDateTimeRangePicker
      value={[value[0] ? new Date(value[0]) : null, value[1] ? new Date(value[1]) : null]}
      onChange={onChange}
      clearIcon={null}
      disableClock={true}
      calendarIcon={<StyledCalendarIcon />}
      invalid={invalid}
    />
  );
};
